@layer mantine, reset, base, overrides;
@import '@mantine/core/styles.css' layer(mantine);
@import '@mantine/carousel/styles.css' layer(mantine);
@import '@mantine/dates/styles.css' layer(mantine);
@import '@mantine/notifications/styles.css' layer(mantine);
@import '@mantine/spotlight/styles.css' layer(mantine);
@import '../styles/surveys.css' layer(base);

@layer reset {
  :root {
    a {
      text-decoration: none;
      color: 'black';
    }
  }

  html {
    font-family: var(--font-avenir);
  }

  body {
    ::-webkit-scrollbar {
      width: '0.25em';
    }
    ::-webkit-scrollbar-track {
      background: '#1e1e24';
    }
    ::-webkit-scrollbar-thumb {
      background: var(--mantine-color-brand-6);
    }

    background-color: var(--mantine-color-gray-0);
    padding: 0;
    margin: 0;
  }

  h1-h6 {
    font-family: var(--font-monument-regular);
  }

  a {
    color: inherit;
  }

  a :visited {
    color: inherit;
  }

  p {
    padding: 2px;
    margin: 0px;
  }
}

@layer base {
  .action-icon {
    background-color: none;
  }

  .button {
    background-color: var(--mantine-color-brand-5);
    &:hover {
      background-color: var(--mantine-color-brand-9);
    }

    &[data-variant='auth'] {
      background-color: var(--mantine-color-brand-6);
      &:hover {
        background-color: var(--mantine-color-brand-8);
      }
    }

    &[data-variant='outline'] {
      background-color: white;
      border: 1px solid var(--mantine-color-brand-4);
      color: var(--mantine-color-brand-4);
      &:hover {
        background-color: var(--mantine-color-brand-4);
        color: white;
      }
    }

    &[data-variant='remove'] {
      background-color: var(--mantine-color-red-6);
      &:hover {
        background-color: var(--mantine-color-red-9);
      }
    }

    &:disabled {
      background-color: var(--mantine-color-gray-2);
      color: var(--mantine-color-gray-6);
    }
  }

  .mantine-Tooltip-tooltip {
    background-color: white;
    color: black;
    border: 1px solid var(--mantine-colors-gray-4);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);
  }

  .input {
    font-weight: 500;
  }

  .menu-item {
    &:hover {
      color: var(--mantine-color-brand-6);
      a {
        color: var(--mantine-color-brand-6);
      }
    }
  }

  .text {
    @apply text-2xl;
    font-weight: 300;
  }

  .icon-select-control {
    width: '16rem';
    display: 'flex';
    justify-content: 'space-between';
    align-items: 'center';
    padding: '10px 15px';
    border-radius: '0.25rem';
    border: '1px solid var(--mantine-colors-gray-2)';
    transition: 'background-color 150ms ease';
    background-color: 'white';

    .opened {
      background-color: var(--mantine-colors-gray-0);
    }

    &:hover {
      background-color: var(--mantine-colors-gray-0);
    }
  }

  .icon-select-label {
    font-weight: 500;
    font-size: var(--mantine-font-size-sm);
  }

  .norm-builder-list-item {
    padding-bottom: '8px';
    & > div {
      width: '100%';
    }
  }

  .charter-card-drag-item {
    display: 'flex';
    align-items: 'center';
    border-radius: '0.25rem';
    border: '1px solid var(--mantine-colors-gray-2)';
    padding: 'var(--mantine-spacing-sm) var(--mantine-spacing-sm)';
    background-color: 'white';
    margin-bottom: var(--mantine-spacing-sm);

    .item-dragging {
      box-shadow: var(--mantine-shadow-sm);
    }
  }

  .charter-card-drag-handle {
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
    height: '100%';
    color: var(--mantine-colors-gray-5);
    padding-left: 0;
    padding-right: 0;
  }

  .modal {
    z-index: 10000;
  }

  .modal-header {
    padding-right: '0.5rem';
    padding-left: 24px;
  }

  .modal-title {
    padding-left: 1rem;
    padding-top: '1rem';
  }

  .multi-select-input {
    font-size: 16px;
    height: 32px;
  }

  .stack {
    &[data-variant='shadow'] {
      padding: 0.75rem;
      /* background-color: red; */
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      border: 1px solid var(--mantine-colors-gray-2);
      border-radius: 0.5rem;
    }
  }

  .icon {
    float: right;
    font-size: 500%;
    position: absolute;
    top: 0rem;
    right: -0.3rem;
    opacity: 0.16;
  }

  .grey-dark {
    background: #495057;
    color: #efefef;
  }

  .red-gradient {
    background: linear-gradient(180deg, rgba(207, 82, 82, 1) 0%, rgba(121, 9, 9, 1) 80%);
    color: #fff;
  }
  .red {
    background: #a83b3b;
    color: #fff;
  }

  .purple {
    background: #886ab5;
    color: #fff;
  }

  .orange {
    background: #ffc241;
    color: #fff;
  }

  .kpi-card {
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
    display: inline-block;
    padding: 1em;
    border-radius: 0.3em;
    width: 260px;
    min-width: 180px;
    margin-left: 0.5em;
    margin-top: 0.5em;
  }

  .card-value {
    display: block;
    font-size: 220%;
    font-weight: bolder;
  }

  .card-text {
    display: block;

    padding-left: 0.2em;
  }

  .auth-button {
    color: red;

    & a {
      text-transform: 'uppercase';
    }
    &:hover {
      background-color: red;
    }
  }

  .card {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 0rem;
    width: fit-content;
  }
}
